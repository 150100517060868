/*
   Copyright 2023, Guillermo Vega-Gorgojo

   Licensed under the Apache License, Version 2.0 (the "License");
   you may not use this file except in compliance with the License.
   You may obtain a copy of the License at

       http://www.apache.org/licenses/LICENSE-2.0

   Unless required by applicable law or agreed to in writing, software
   distributed under the License is distributed on an "AS IS" BASIS,
   WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
   See the License for the specific language governing permissions and
   limitations under the License.
*/
import config from './app/data/config.json';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './app/css/bootstrap-avatar.css';
import "./app/css/landEducawood.css";

import _ from 'underscore';
import bootstrap from "bootstrap/dist/js/bootstrap.bundle.min.js";
import $ from "jquery";
import { initializeApp } from "firebase/app";
import { GoogleAuthProvider, getAuth, signInWithRedirect, onAuthStateChanged, signOut } from "firebase/auth";


// inicialización firebase
// TODO: config.firebaseConfig.authDomain: "localhost", // cambiar en producción a "educawood.gsic.uva.es",
let app = initializeApp(config.firebaseConfig);
let provider;

// idioma
const path = window.location.pathname;
let lang = localStorage.getItem('lang');
if (!lang) {
	// elijo automáticamente a partir del lenguaje del navegador
	const preflangs = window.navigator.languages || [window.navigator.language || window.navigator.userLanguage];
	for (let ltag of preflangs) {
		const langev = ltag.substring(0, 2);
		if (_.contains(config.langs, langev)) {
			lang = langev;
			break;
		}
	}
	if (!lang)
		lang = config.langs[0]; // inglés
}
if (path === "/" && lang === "es") // prefiere español
	window.location.href = 'es';
else if (path === "/es" && lang === "en") // prefiere inglés
	window.location.href = '..';
else {
	// rutina autenticación
	autenticacionLanding();

	// listener del cambio de idioma
	$('.lang').click(function() {
		// Prevent the default behavior of the link
		event.preventDefault();
		// recupero idioma
		let nlang = $(this).attr("tag");
		// guardo en localstorage
		localStorage.setItem('lang', nlang);
		// miro si hay que cambiar
		if (path === "/" && nlang === "es") // prefiere español
			window.location.href = 'es';
		else if (path === "/es" && nlang === "en") // prefiere inglés
			window.location.href = '..';
	});			
}

function clickSignin(e) {
	e.preventDefault(); // Prevent the default behavior of the hyperlink
	const auth = getAuth();
	signInWithRedirect(auth, provider);
}
function clickSignout(e) {
	e.preventDefault(); // Prevent the default behavior of the hyperlink
	const auth = getAuth();
	signOut(auth);
}
function autenticacionLanding() {
	// inicialización
	provider = new GoogleAuthProvider();
	const auth = getAuth();
	auth.useDeviceLanguage();
	// handler para detectar si hay usuario
	onAuthStateChanged(auth, async (user) => {
		if (user) {
			// muestro avatar con dropdown de signout (landing)
			// no guardo user			
			$("#aProfile").attr("href", "/user/" + user.uid);
			const htmlImg = user.photoURL?
				'<img class="avatar avatar-32 avatar-sm-48 bg-light rounded-circle text-white" \
						src="' +user.photoURL+ '" referrerpolicy="no-referrer">' :
				'<img class="avatar avatar-32 avatar-sm-48 bg-light rounded-circle text-white p-1 p-sm-2" \
						src="https://raw.githubusercontent.com/twbs/icons/main/icons/person.svg" \
						referrerpolicy="no-referrer">';
			$("#botonAvatar").html(htmlImg);
			$("#divAvatar").removeClass("d-none");
			$("#botonSignin").addClass("d-none");
		} else {
			// muestro botón de signin (landing)
			$("#divAvatar").addClass("d-none");
			$("#botonSignin").removeClass("d-none");
		}
	});
	// pongo listeners a clickViewprofile, clickSignin y clickSignout
	$(".usersignin").click(clickSignin);
	$(".usersignout").click(clickSignout);
}
